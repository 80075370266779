import styled from 'styled-components'
import { StyledNavItem } from './NavItem'
import AppBar from '@material-ui/core/AppBar'
import { StyledDropdownPanel } from '../NavigationDropdown/NavDropdownPanel'

export const StyledNavigationContainer = styled(AppBar)<{ $secondary?: boolean; $sticky?: boolean, $visibleHeader?:boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.palette.primary.dark};
  box-shadow: none;
  background: ${props => props.$sticky ? 'white' : 'none'};
  width: 100%;
  height: ${props => props.theme.typography.pxToRem(74)};
  padding: 0 ${props => props.theme.typography.pxToRem(24)};
  border-bottom: ${props => props.$sticky && '1px solid rgba(0, 0, 0, 0.2)'};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => (props.$secondary || props.$sticky) ? props => props.theme.typography.pxToRem(74) : props.theme.typography.pxToRem(128)};
    padding: 0 ${props => props.theme.typography.pxToRem(48)} 0 ${props => props.theme.typography.pxToRem(65)};
  }

  ${StyledNavItem} {
    &::after {
      transform: ${props => props.$secondary ? 'translateY(10px)' : 'translateY(20px)'};
    }

    padding-top: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(24) : props.theme.typography.pxToRem(40))};
    padding-bottom: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(24) : props.theme.typography.pxToRem(40))};
  }

  ${StyledDropdownPanel} {
    top: ${props => (props.$secondary || props.$sticky) ? props => props.theme.typography.pxToRem(68) : props.theme.typography.pxToRem(120)};
  }
`

export const StyledMenuContainer = styled.div`
  margin-left: auto;
`

export const StyledRightMenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  order: 2;
`
